@import "tailwind.scss";

.jtw-alert-danger {
  @apply tw-text-xs tw-font-normal tw-text-joboo-red-darker tw-bg-joboo-red-light tw-border-solid tw-border tw-mb-4 tw-rounded tw-border-joboo-red-dark tw-relative md:tw-text-sm sm:tw-px-6 sm:tw-py-3;
}

.jtw-form-enhanced-checkbox {
  @apply tw-bg-joboo-gray-light tw-rounded-md tw-mx-auto tw-relative md:tw-h-checkbox-base md:tw-w-checkbox-base tw-my-0 sm:tw-h-checkbox-md sm:tw-w-checkbox-md sm:tw-rounded-sm xs:tw-h-checkbox-sm xs:tw-w-checkbox-sm xs:tw-rounded-none;
}

.jtw-form-enhanced-checkbox-input {
  @apply tw-invisible;
}

.jtw-form-enhanced-checkbox label {
  @apply tw-opacity-0 tw-rounded-sm tw-absolute tw-top-0 tw-bottom-0 tw-z-[1] tw-mt-0 tw-bg-white sm:tw-text-center tw-font-light tw-text-black sm:tw-text-sm md:tw-h-checkbox-base md:tw-w-checkbox-base md:tw-text-xl sm:tw-h-checkbox-md sm:tw-w-checkbox-md sm:tw-rounded-sm tw-flex tw-flex-col tw-justify-center tw-items-center xs:tw-h-checkbox-sm xs:tw-w-checkbox-sm xs:tw-rounded-none xs:tw-text-[0.5rem];
}

.jtw-form-enhanced-checkbox-input:checked + label {
  opacity: 1;
  background-color: #ceebfa !important;
}

.jtw-form-card {
  @apply tw-relative tw-mb-5 tw-border-none tw-shadow-lg tw-font-medium tw-rounded-md tw-flex-col tw-bg-white;
}

.jtw-form-card-header {
  @apply tw-w-full tw-bg-joboo-blue-default tw-mr-9 xs:tw-pl-2 md:tw-pl-12 tw-ml-0 tw-py-3 tw-font-bold tw-text-2xl tw-rounded-sm md:tw-text-2xl xs:tw-text-lg;
}

.jtw-form-card-main-content {
  @apply tw-flex tw-flex-col tw-justify-between tw-items-center tw-pt-1 tw-pb-12 xs:tw-px-1 md:tw-px-12;
}

.jtw-form-block-bordered {
  @apply tw-rounded tw-mb-2 tw-py-3 tw-shadow-none tw-w-full md:tw-mb-2;
}

.jtw-form-search-block-wrapper {
  @apply xs:tw-mb-1 md:tw-mb-0 lg:tw-flex-row xs:tw-flex-col tw-flex tw-items-start lg:tw-space-x-2 tw-w-full;
}

.jtw-form-search-label-container {
  @apply tw-relative tw-mt-6 tw-w-full;
}

.jtw-form-invalid-note {
  @apply tw-p-0 tw-hidden tw-absolute md:tw-text-sm xs:tw-text-[0.5rem];
}

.jtw-form-search-label {
  @apply tw-text-joboo-gray-default tw-font-light tw-cursor-text tw-text-base tw-left-0 tw-absolute tw-top-2 tw-translate-y-[8px] tw-origin-[0_100%] tw-transform tw-duration-200 tw-ease-out tw-pl-2;
  transition: transform 0.2s ease-out, color 0.2s ease-out;
}

.jtw-form-search-input {
  @apply focus:tw-shadow-none tw-w-full tw-mb-0 tw-text-base tw-flex tw-items-center tw-justify-between;
}

.jtw-form-search-label.active {
  @apply xs:tw-text-xs md:tw-text-base tw-pl-0;
  transform: translateY(-30px) scale(0.8);
}


.jtw-form-dropdown-items {
  @apply tw-z-[1000] tw-text-left tw-rounded tw-w-full md:tw-max-h-32 tw-overflow-y-scroll xs:tw-max-h-32 xs:tw-text-base md:tw-text-sm tw-py-0;
}

.jtw-form-btn-wrapper {
  @apply tw-flex tw-flex-col tw-justify-between tw-items-center tw-mt-1;
}

.jtw-form-button {
  @apply tw-text-black tw-uppercase tw-text-center tw-border-none md:tw-rounded xs:tw-rounded-sm tw-py-2 tw-px-4 tw-text-sm tw-my-2 hover:tw-text-black tw-transition tw-ease-in-out tw-shadow-sm tw-mx-0 xs:tw-mr-2 hover:tw-shadow-xl;
}

.jtw-form-button-small {
    @apply tw-text-black tw-uppercase tw-text-center tw-border-none md:tw-rounded xs:tw-rounded-sm xs:tw-py-1 md:tw-py-2 md:tw-px-4 xs:tw-px-2 lg:tw-text-sm xs:tw-text-[0.75rem] xs:tw-my-1 md:tw-my-2 hover:tw-text-black tw-transition tw-ease-in-out tw-shadow-sm tw-mx-0 xs:tw-mr-2;
}

.jtw-form-button-default {
  @apply tw-bg-joboo-blue-default;
}
.jtw-form-button-succcess {
  @apply tw-bg-joboo-green-default tw-text-white hover:tw-text-white;
}

.jtw-form-popular-jobs {
  @apply tw-cursor-pointer tw-ml-1 tw-flex tw-flex-row tw-items-center;
}

.jtw-form-popular-jobs-headline {
  @apply tw-text-xs;
}

.jtw-form-linked-text {
  @apply tw-cursor-pointer md:tw-pr-2 tw-py-0 tw-my-0 tw-text-left tw-inline tw-underline tw-text-black hover:tw-text-joboo-blue-default hover:tw-underline tw-font-bold;
}

.jtw-form-popular-jobs-container {
  @apply md:tw-inline-block tw-font-normal tw-text-sm tw-items-start tw-w-full xs:tw-ml-0;
}

.jtw-form-section-collapsible-label {
  @apply tw-mb-0 md:tw-font-bold xs:tw-font-semibold /* xs:tw-text-xs  */tw-text-sm sm:tw-pr-1 md:tw-pr-3 tw-py-1 tw-cursor-pointer tw-whitespace-nowrap;
}

.jtw-form-section-label-badge {
  @apply tw-bg-joboo-gray-light tw-text-black xs:tw-p-1 sm:tw-p-2 tw-rounded tw-mr-2 md:tw-text-xs tw-font-bold xs:tw-text-[0.5rem];
}

.jtw-form-deactivate-button {
  @apply tw-relative xs:tw-text-[0.5rem] md:tw-text-base;
}

.jtw-form-weektime-table-border {
  @apply tw-border-t tw-border-t-[#dee2e6] tw-border-solid tw-border-b-0 tw-border-l-0 tw-border-r-0;
}

.jtw-form-weektime-table-text {
  @apply tw-text-center sm:tw-text-xs md:tw-text-base xs:tw-text-[0.5rem];
}

.jtw-form-filter-checkbox-input {
  @apply tw-accent-joboo-blue-default md:tw-h-[20px] md:tw-w-[20px] xs:tw-h-[12px] xs:tw-w-[12px] xs:tw-mt-2 md:tw-mt-1 md:tw-mr-2;
}

.jtw-form-filter-checkbox-label {
  @apply md:tw-ml-3 xs:tw-font-light md:tw-font-normal xs:tw-text-xs md:tw-text-base;
}

.jtw-form-checkbox-list-wrapper {
  @apply md:tw-mb-2;
}

.jtw-form-checkbox-list-wrapper:hover .jtw-form-filter-checkbox-input {
  @apply tw-accent-joboo-blue-default;
}

.jtw-form-filter-checkboxList-block-label {
  @apply tw-font-bold md:tw-mb-3 xs:tw-mb-1 xs:tw-text-xs md:tw-text-base;
}

.jtw-form-popularjobs-alternativ-note {
  @apply tw-text-joboo-gray-default;
}

.jtw-form-popular-jobs-wrapper {
  @apply tw-flex tw-flex-row tw-justify-start tw-items-center xs:tw-gap-y-0.5 tw-ml-1;
}

.jtw-form-weektime-daytime-note {
  @apply sm:tw-text-xs xs:tw-text-[0.25rem] xs:tw-mb-0 sm:tw-mb-4;
}

.jtw-form-dropdown-item {
  @apply hover:tw-bg-[#f8f9fa] md:tw-my-0 tw-pl-2 tw-py-1 xs:tw-my-0 tw-text-[#495057] tw-cursor-pointer tw-border-b-0 tw-border-x-0 tw-border-solid tw-border-t tw-border-joboo-gray-light;
}

.jtw-form-dropdown-label {
  @apply tw-mb-0;
}

.jtw-form-dropdown-item-title {
  @apply tw-mb-2 tw-pl-2 tw-pb-1;
}

.jwt-form-filterZipcodeRadius-dropdown {
  @apply tw-flex tw-flex-row tw-justify-start tw-content-start;
}
